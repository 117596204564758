import React from 'react'
import Nav from 'react-bootstrap/lib/Nav'
import Navbar from 'react-bootstrap/lib/Navbar'
import * as Scroll from 'react-scroll'

const Header = location => {
  const navLink = (id, text) => {
    return (
      <li>
        <Scroll.Link
          className="nav-link"
          activeClass="is-current"
          href={`#${id}`}
          to={id}
          hashSpy={true}
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          {text}
        </Scroll.Link>
      </li>
    )
  }

  const navLinks = () => {
    if (location && location.location && location.location.pathname === '/') {
      return (
        <Nav>
          {navLink('home', 'Home')}
          {navLink('features', 'Features')}
          {navLink('about', 'About')}
          {navLink('download', 'Download')}
        </Nav>
      )
    }

    return (
      <Nav>
        <li>
          <Nav.Link href={`/`}>Home</Nav.Link>
        </li>
        <li>
          <Nav.Link
            active={location.location.pathname.startsWith('/privacy')}
            href={`/privacy`}
          >
            Privacy Policy
          </Nav.Link>
        </li>
        <li>
          <Nav.Link
            active={location.location.pathname.startsWith('/terms')}
            href={`/terms`}
          >
            Terms of Service
          </Nav.Link>
        </li>
      </Nav>
    )
  }

  return (
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" sticky="top">
      <Navbar.Brand href="/#">QUOPI</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" />
        {navLinks()}
      </Navbar.Collapse>
    </Navbar>
  )
}
export default Header
